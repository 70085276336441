import { User } from '../models/user.model';

export interface UserLoggedInPayload {
  user: User;
  accessToken: string;
}

export class UserLoggedIn {
  public static readonly type = '[Auth] Logged In';
  constructor(public payload: UserLoggedInPayload) {}
}

export class UserAllowedClaimsPreAuth {
  static readonly type = '[AUTH] User Allowed Claims Pre-Auth';
}

// export class UpdateProfilePhoto {
//   public static readonly type = '[Profile Component] Set user profile photo';
//   constructor(public payload: File) {}
// }

// export class UpdateUserPassword {
//   public static readonly type = '[Profile Component] Update user password';
//   constructor(public payload: string) {}
// }

// export class Login {
//   public static readonly type = '[Login Page] Login';
//   constructor(public payload: { username: string; password: string }) {}
// }

// export class LoginAfterPasswordChange {
//   public static readonly type = '[Change Password Page] Login after password change';
//   constructor(public payload: { username: string; password: string }) {}
// }

// export class Register {
//   public static readonly type = '[Register Page] Register';
//   constructor(public payload: UserRegister) {}
// }

// export class Logout {
//   public static readonly type = '[Auth] Logout';
// }

// export class RefreshedToken {
//   public static readonly type = '[HTTP Interceptor] Token Refresh';
//   constructor(public payload: { accessToken: string }) {}
// }

// export class SetUserDealerCode {
//   public static readonly type = '[Auth State] Set users Dealer Code';
//   constructor(public payload: string) {}
// }
