import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@mbp/environment';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { distinctUntilChanged, filter, take, map } from 'rxjs/operators';
import { RoleNameType, RoleType } from '../models/enums';
import { User } from '../models/user.model';
import { AlertService, ToastType } from '../services/alert.service';
import { UserAllowedClaimsPreAuth, UserLoggedIn } from './auth.actions';
import { FetchDealerCount, SetDealerCode } from './dealer.actions';
import { createPropertySelectors } from './ngxs-next';
import { UserProfileService } from '../api/user-profile.service.js';
import { patch } from '@ngxs/store/operators';

const { profilePhotoBaseUrl, noAvatar } = environment;

export interface AuthStateModel {
  user: User;
  accessToken: string;
  currentUserIsDealer: boolean;
  currentUserIsAgent: boolean;
  currentUserIsMBPI: boolean;
  currentUserIsCustomer: boolean;
  allowedClaimsPreAuth: boolean;
}

const defaultState: AuthStateModel = {
  user: null,
  accessToken: null,
  currentUserIsDealer: null,
  currentUserIsAgent: null,
  currentUserIsMBPI: null,
  currentUserIsCustomer: null,
  allowedClaimsPreAuth: null,
};

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

// FIXME: We need to check if a user is logged in or not on page reload and dispatch affected actions
// ie if a user is logged in we should fetch all dealer/agency information so state is up to date
@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: { ...defaultState },
})
@Injectable()
export class AuthState implements NgxsAfterBootstrap {
  public static props = createPropertySelectors(AUTH_STATE_TOKEN);

  @Selector([AUTH_STATE_TOKEN])
  public static userName(state: AuthStateModel) {
    return state.user?.username;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static getIsLoggedIn(state: AuthStateModel) {
    return !!state.user;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static profilePhotoUrl(state: AuthStateModel) {
    return state.user.profilePhotoUrl;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static userIsDealerOrMbpi(state: AuthStateModel) {
    return state.currentUserIsDealer || state.currentUserIsMBPI;
  }

  @Selector([AuthState.getAgentCode])
  public static userIsRtda(agentCode: string) {
    return agentCode === 'RTDA';
  }

  @Selector([AuthState.props.currentUserIsAgent, AuthState.props.currentUserIsMBPI])
  public static userIsMbpiOrAgent(userIsAgent: boolean, userIsMbpi: boolean) {
    return userIsAgent || userIsMbpi;
  }

  @Selector([AuthState.props.user])
  public static getUserRoles(user: User) {
    return user?.roles ?? [];
  }

  @Selector([AuthState.getUserRoles])
  public static getIsMasterAgent(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'master agent');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsMasterDealer(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'master dealer');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsServiceUser(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'service user');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsServiceUserLimited(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'service user limited');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsAdministrator(roles: string[]) {
    return roles?.some((r) => r === RoleNameType[RoleNameType.MBPIAdministrator] || r === RoleNameType[RoleNameType.SiteAdministrator]);
  }

  @Selector([AuthState.props.user])
  public static usersDealerCode(user: User) {
    return user?.dealerCode;
  }

  @Selector([AuthState.props.user])
  public static getAgentCode(user: User) {
    return user?.agentCode;
  }

  constructor(private router: Router, private alert: AlertService, private store: Store, private userProfileService: UserProfileService) {}

  ngxsAfterBootstrap(ctx?: StateContext<AuthStateModel>): void {
    try {
      window.localStorage.removeItem('auth');
    } catch (error) {
      console.log(error);
    }

    // [IT-3765] Do we need to figure this out to work with kratos?
    // this.store
    //   .select(AuthState.props.user)
    //   .pipe(
    //     filter((user) => user?.requirePasswordChange),
    //     distinctUntilChanged(isEqual),
    //   )
    //   .subscribe((user) => {
    //     if (user.requirePasswordChange) {
    //       this.alert
    //         .showStickyMessage('Change Password', 'You are required to change your password. Click here to change it.', ToastType.warning)
    //         .onTap.pipe(take(1))
    //         .subscribe(() => {
    //           this.router.navigate(['/user/profile']);
    //         });
    //     }
    //   });
  }

  @Action(UserLoggedIn)
  public async userLoggedIn({ dispatch, patchState }: StateContext<AuthStateModel>, { payload }: UserLoggedIn) {
    const { accessToken, user } = payload;

    const currentUserIsDealer = !!user.dealerCode;
    const currentUserIsAgent = !!user.agentCode && !currentUserIsDealer;
    const currentUserIsMBPI = !!user.isActiveDirectoryUser;
    const currentUserIsCustomer = user.roleType === RoleType.Customer;
    const currentUserIsRtda = user.agentCode === 'RTDA';

    patchState({
      user: {
        ...user,
        profilePhotoUrl: `${profilePhotoBaseUrl}/${user.profilePhotoUrl || noAvatar}`,
      },
      accessToken,
      currentUserIsDealer,
      currentUserIsAgent,
      currentUserIsMBPI,
      currentUserIsCustomer,
      allowedClaimsPreAuth: currentUserIsMBPI,
    });

    dispatch(new SetDealerCode(user.dealerCode));
    dispatch([new FetchDealerCount()]);
    if (!currentUserIsMBPI) dispatch([new UserAllowedClaimsPreAuth()]);
  }

  @Action(UserAllowedClaimsPreAuth)
  userAllowedClaimsPreAuth(ctx: StateContext<AuthStateModel>) {
    return this.userProfileService.getAllowedClaimsPreAuth().pipe(
      map((allowed) => {
        ctx.setState(
          patch<AuthStateModel>({
            allowedClaimsPreAuth: allowed,
          }),
        );
      }),
    );
  }
}
